import React from 'react';
import Modal from 'rsuite/Modal';
import Button from 'rsuite/Button';
import Form from 'rsuite/Form';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import styles from 'public/styles/ChangePinNotification.module.css';
import { getUserToken } from 'utils';
import ErrorDiv from 'components/shared/ErrorDiv';

const ChangePinNotification = ({ user }) => {
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const formik = useFormik({
        initialValues: {
            transactionPin: '',
            confirmTransactionPin: '',
        },

        onSubmit: (values, helpers) => {
            const token = getUserToken();
            let newValues = {};
            newValues.transactionPin = values.transactionPin;
            newValues.userId = user?.userId;
            return axios
                .put(`${process.env.NEXT_PUBLIC_BASE_URL}/wallet/setup-pin`, newValues, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                })
                .then((res) => {
                    toast.success('Your Transaction Pin Have Been Set Successfully');
                    helpers.setSubmitting(false);
                    helpers.resetForm();
                    handleClose();
                })
                .catch((err) => {
                    console.log('errr', err);
                    helpers.setSubmitting(false);
                    if (err?.response?.data?.responsemessage?.msg) {
                        return toast.error(err?.response?.data?.responsemessage?.msg);
                    }
                    if (err?.response?.data?.responsemessage) {
                        return toast.error(err?.response?.data?.responsemessage);
                    }
                    if (err?.message) {
                        return toast.error(err?.message);
                    }
                });
        },
        validationSchema: yup.object().shape({
            transactionPin: yup.string().min(4).max(4).required().label('Pin'),
            confirmTransactionPin: yup
                .string()
                .oneOf([yup.ref('transactionPin'), null], 'Transaction Pin must match'),
        }),
    });
    return (
        <Modal
            style={{
                minHeight: '90vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            backdrop={'static'}
            keyboard={false}
            open={open}
            onClose={handleClose}
        >
            <Modal.Header>
                <Modal.Title className={styles.modalTitle}>Hello {user?.firstname}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className={styles.modalSubTitle}>
                    Set up your transaction pin to enable you make easy and secure transactions
                </p>
                <br />
                <div className="profile-container">
                    <Form fluid autoComplete="off" onSubmit={formik.handleSubmit}>
                        <input
                            autoComplete="false"
                            name="hidden"
                            type="text"
                            style={{ display: 'none' }}
                        />

                        <Form.Group controlId="transactionPin">
                            <Form.ControlLabel>Transaction Pin</Form.ControlLabel>
                            <Form.Control
                                autocomplete="off"
                                name="transactionPin"
                                type="password"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                    formik.setFieldValue('transactionPin', e);
                                }}
                            />

                            {formik.errors.transactionPin && formik.touched.transactionPin ? (
                                <ErrorDiv msg={formik.errors.transactionPin} />
                            ) : null}
                        </Form.Group>
                        <Form.Group controlId="confirmTransactionPin">
                            <Form.ControlLabel>Confirm Transaction Pin</Form.ControlLabel>
                            <Form.Control
                                autocomplete="off"
                                name="confirmTransactionPin"
                                type="password"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                    formik.setFieldValue('confirmTransactionPin', e);
                                }}
                            />

                            {formik.errors.confirmTransactionPin &&
                            formik.touched.confirmTransactionPin ? (
                                <ErrorDiv msg={formik.errors.confirmTransactionPin} />
                            ) : null}
                        </Form.Group>

                        <Form.Group className={styles.buttonDiv}>
                            <Button
                                className={styles.buttonStyle}
                                disabled={formik.isSubmitting}
                                appearance="primary"
                                color="green"
                                type="submit"
                            >
                                {formik.isSubmitting ? 'Processing' : 'Continue'}
                            </Button>
                        </Form.Group>
                        <Form.Group className={styles.buttonDiv}>
                            <Button
                                className={styles.buttonStyle}
                                disabled={formik.isSubmitting}
                                appearance="primary"
                                type="button"
                                onClick={handleClose}
                            >
                                No, I will set my pin later
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button onClick={handleClose} appearance="primary">
                        Ok
                    </Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default ChangePinNotification;
