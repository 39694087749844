import React from 'react';
import Head from 'next/head';

const MetaDecorator = ({
    title = 'Gaim Techonologies Limited',
    description = 'Gaim Techonologies Limited',
    ogImage = '/images/logo1.jpg',
}) => {
    return (
        <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta charSet="utf-8" />
            <title>{'Gaim Technologies - Lotto Application White Label'}</title>
            <meta name="description" content={description} />
            {/* <link rel="apple-touch-icon" href="/images/apple-touch-icon.png" /> */}

            <meta property="og:title" content={title} key="ogTitle" />
            <meta property="og:type" content="article" key="ogType" />
            <meta property="og:description" content={description} key="ogDescription" />
            <meta property="og:image" itemProp="image" content={ogImage} key="ogImage" />
            {/* <meta property="og:url" content="http://web-betja.gamepro.tech/" key="ogurl" /> */}
            {/* <meta property="og:site_name" content="Yellow Lotto" key="ogSiteName" /> */}
            <meta name="twitter:card" content="summary_large_image" key="twCard" />
            <meta name="twitter:title" content={title} key="twTitle" />
            <meta name="twitter:image" content={ogImage} key="twImage" />

            <link rel="icon" href="/images/favicon.ico" />
            <link rel="manifest" href="/site.webmanifest" />
        </Head>
    );
};

export default MetaDecorator;
