import * as React from 'react';
import Loader from 'rsuite/Loader';

export default function FullScreenLoader() {
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '80vh',
                color: 'white',
            }}
        >
            <Loader size="md" content="Loading..." />
        </div>
    );
}
