import React from 'react';
import Countdown from 'react-countdown';
import { POOL_OF_FAME, formatGameDate } from 'utils';
import styles from './Timer.module.css';

const Timer = ({ game }) => {
    const timeContStyles = {
        position: 'absolute',
        right: '4px',
        top: '10px',
        marginTop: '4px',
    };
    let timeStyles = {
        width: '60px',
        fontSize: '13px',
        color: 'white',
        backgroundColor: '#000000',
        borderRadius: '5px',
        padding: '10px',
        margin: '2px',
    };
    const renderer = ({ hours, minutes, seconds }) => {
        return (
            <div style={timeContStyles}>
                <span style={timeStyles}>{hours}h</span>
                <span style={timeStyles}>{minutes}m</span>
                <span style={timeStyles}>{seconds}s</span>
            </div>
        );
    };

    if (!game) {
        return null;
    }
    // console.log('game', game);
    let currentHour = new Date().getHours();

    let date = new Date();

    let toFormatEndTime = formatGameDate(game?.endTime, true);
    let toFormatStartTime = formatGameDate(game?.startTime, true);

    const startTimeArray = toFormatStartTime?.split(':');
    const endTimeArray = toFormatEndTime?.split(':');

    date.setHours(endTimeArray[0]);
    date.setMinutes(endTimeArray[1]);
    date.setSeconds(endTimeArray[2]);
    Number(startTimeArray[0]) <= currentHour || currentHour > Number(endTimeArray[0]);

    //   if (Number(startTimeArray[1]) > currentHour) {
    //     return <div>Upcomming</div>;
    //   }
    if (game?.Lottery?.category === POOL_OF_FAME) {
        if (game?.alternateStartDate) {
            return (
                <Countdown
                    date={game?.alternateStartDate}
                    intervalDelay={0}
                    precision={3}
                    renderer={renderer}
                />
            );
        }
        if (Number(game?.currentPoolAmount) < Number(game?.totalFundPool)) {
            return <div className={styles.runningDiv}>Running</div>;
        }
        if (Number(game?.currentPoolAmount) >= Number(game?.totalFundPool)) {
            return <div className={styles.endedDiv}>Ended</div>;
        }
    }
    return <Countdown date={date} intervalDelay={0} precision={3} renderer={renderer} />;
};

export default Timer;
