import React from 'react';
import Panel from 'rsuite/Panel';
import Button from 'rsuite/Button';
import styles from 'public/styles/GamesCard.module.css';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { handleSetLotteryCategory } from 'redux/actions/lotteryActions';
import Timer from './Timer';

export default function GamesImageCard({ game }) {
    const router = useRouter();
    const dispatch = useDispatch();
    // console.log('bbbbbbb', game);
    const handleChangeRoute = (data) => {
        dispatch(handleSetLotteryCategory(data?.category));
        router.push(
            `/lottery/${data?.lotteryId}/${data?.Lottery?.slug}?lotteryCategory=${data?.Lottery?.category}&gameId=${data?.gameId}`,
        );
    };
    return (
        <Panel
            shaded
            bordered
            bodyFill
            style={{
                background: game?.imageUrl ? `url(${game?.imageUrl})` : `url(/images/cardbg.png)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                backgroundPosition: 'center',
            }}
            className={styles.panel}
        >
            <div className={styles.imageDiv}>
                <Timer game={game} />
                <div className={styles.gameTitleCard}>
                    <h6 style={{ fontWeight: '600' }}>{game?.name}</h6>
                </div>
            </div>
            <Panel>
                <br />
                <Button
                    className={styles.btn}
                    appearance="primary"
                    color="green"
                    block
                    onClick={() => handleChangeRoute(game)}
                >
                    Play Now
                </Button>
            </Panel>
        </Panel>
    );
}
